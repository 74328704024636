import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//import des pages (cotainers)de l'application
import Home from "./containers/Home";
import Product from "./containers/Product";

// Application Web
/*
1. lister les pages
2. creer un composant par page
3. creer des liens entre les pages
*/

// Gestion de l'authentification se fait dans App

//Sinon les autres states se gèrent directement dans les pages

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/product/:id">
            <Product />
          </Route>
          <Route path="/">
            {/* A la possibilité d'avoir des children */}
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

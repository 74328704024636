import React from "react";
import { useParams, Link } from "react-router-dom";

const Product = (props) => {
  const { id } = useParams();
  console.log(props);
  console.log(id);
  return (
    <>
      <div>Product</div>
      <div>
        <Link to="/">Go to Home</Link>
      </div>
    </>
  );
};

export default Product;

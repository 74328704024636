import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div>Home</div>
      <a href="http://www.google.fr">Aller sur Google</a>
      <br />
      <Link to="/product/12">Aller à la page prodtuct</Link>
    </>
  );
};

export default Home;
